@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* RESET  [ Styleguide.ArccosGolf.com ] */
html {
    font-size: 14px;
    height: 100%;
    font-family: sans-serif;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
blockquote,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    box-sizing: border-box;
    font-family: inherit;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

a {
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

/* [ HTML5 Block Elements ] */
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    font-size: 0.9rem;
    min-height: 100%;
}

code,
pre {
    font-family: monospace;
    font-size: 16px
}

dd {
    padding-bottom: 15px;
}

dt {
    font-weight: bold;
    font-size: 115%;
    padding: 10px 0;
}

input,
button,
textarea,
select,
optgroup,
option {
    border: 1px solid #999;
    border-radius: 4px;
    font-style: inherit;
    font-weight: inherit;
    height: 50px;
    line-height: 50px;
    padding: 5px 10px;
}

ol {
    margin-left: 2em;
}


ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

textarea {
    min-height: 150px;
}

th {
    background-color: #333;
    color: #fff;
    padding: 15px;
    text-transform: capitalize;
}

tr:nth-of-type(even) {
    background-color: #ccc;
}
/* COLORS  [ Styleguide.ArccosGolf.com ] */
.blue, .info { color: #3b94d1 }
.blue-dg { color: #4163b5 }
.green, .grn, .success { color: #5db446 }
.green-20 { color: #93e37d }
.green-10 { color: #70d954 }
.green-dk { color: #4a8f38 }
.red, .error { color: #f15b22 }
.red-dk { color: #bb0000 }
.salmon { color: #ee9f7e }
.gray-1 { color: #333333 }
.gray-2 { color: #595959 }
.gray-3 { color: #a6a6a6 }
.gray-4 { color: #e6e6e6 }
.gray-5 { color: #f2f2f2 }
.gray-6 { color: #fafafa }
.bg-black { background-color: #000; color: #fff }
.bg-blue { background-color: #3b94d1 }
.bg-blue-dg { background-color: #4163B5 }
.bg-green { background-color: #5db446 }
.bg-green-20 { background-color: #93e37d }
.bg-green-10 { background-color: #70d954 }
.bg-green-dk { background-color: #4a8f38 }
.bg-red { background-color: #f15b22 }
.bg-red-dk { background-color: #bb0000 }
.bg-salmon { background-color: #ee9f7e }
.bg-gray-1 { background-color: #333333 }
.bg-gray-2 { background-color: #595959 }
.bg-gray-3 { background-color: #a6a6a6 }
.bg-gray-30 { background-color: #383838 }
.bg-gray-4 { background-color: #e6e6e6 }
.bg-gray-5 { background-color: #f2f2f2 }
.bg-gray-6 { background-color: #fafafa }

/* APP */
.compact {
    line-height: 1.4;
}

.center.error {
    text-align: center;
}

.padded {
    padding-left: 10px;
    padding-right: 10px;
}
body {
    font-family: 'Open Sans', sans-serif;
}
/* Route.css */
.route {
  margin: auto;
  width: 100%;
  height: 100vh;
  max-width: 500px;
}
/* Buttons.css */

.button {
    align-items: center;
    display: flex;
    text-decoration: none;
}

.button, .button:hover {
    text-decoration: none;
}

.retry-button {
  color: #5db446;
  display: inline-flex;
}

.submit-button {
  align-content: center;
  align-items: center;
  background: #5db446;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  margin: 10px;
  text-align: center;
}

.submit-button:not(.expandable) {
  height: 50px;
  line-height: 50px;
}

.submit-button.deactivated {
  background: #a6a6a6;
  cursor: default;
}

.submit-button.reversed-button {
  border: 2px solid #5db446;
  color: #5db446;
  background: #fff;
}
/* Views.css */

.view--centered {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  min-width: 320px;
}

.view--wrapper {
  padding-bottom: 50px;
}

.redirect-container {
  display: flex;
  flex-direction: column;
  background-color: #333;
  height:50vh;
  width:75vw;
  min-width: 320px;
  border-radius: 1.5em;
  color:white;
  justify-content: space-between;
  align-items: center;
}

.redirect-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
  padding-bottom: 50px;
}

.redirect-header {
  width: 100%;
  max-height: 50px;
  /*background-color: blue;*/
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.redirect-logo {
  height:50px;
}

.title {
  background: #333;
  color: #fff;
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 25px;
  padding: 10px;
  margin: 0;
}


.loading{
  -webkit-animation: spin 3s linear  infinite ;
          animation: spin 3s linear  infinite ;
}

@-webkit-keyframes spin {
  to {
    stroke-dashoffset: 0px;
  }
}

@keyframes spin {
  to {
    stroke-dashoffset: 0px;
  }
}
