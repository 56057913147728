/* Buttons.css */

.button {
    align-items: center;
    display: flex;
    text-decoration: none;
}

.button, .button:hover {
    text-decoration: none;
}

.retry-button {
  color: #5db446;
  display: inline-flex;
}

.submit-button {
  align-content: center;
  align-items: center;
  background: #5db446;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  margin: 10px;
  text-align: center;
}

.submit-button:not(.expandable) {
  height: 50px;
  line-height: 50px;
}

.submit-button.deactivated {
  background: #a6a6a6;
  cursor: default;
}

.submit-button.reversed-button {
  border: 2px solid #5db446;
  color: #5db446;
  background: #fff;
}