/* COLORS  [ Styleguide.ArccosGolf.com ] */
.blue, .info { color: #3b94d1 }
.blue-dg { color: #4163b5 }
.green, .grn, .success { color: #5db446 }
.green-20 { color: #93e37d }
.green-10 { color: #70d954 }
.green-dk { color: #4a8f38 }
.red, .error { color: #f15b22 }
.red-dk { color: #bb0000 }
.salmon { color: #ee9f7e }
.gray-1 { color: #333333 }
.gray-2 { color: #595959 }
.gray-3 { color: #a6a6a6 }
.gray-4 { color: #e6e6e6 }
.gray-5 { color: #f2f2f2 }
.gray-6 { color: #fafafa }
.bg-black { background-color: #000; color: #fff }
.bg-blue { background-color: #3b94d1 }
.bg-blue-dg { background-color: #4163B5 }
.bg-green { background-color: #5db446 }
.bg-green-20 { background-color: #93e37d }
.bg-green-10 { background-color: #70d954 }
.bg-green-dk { background-color: #4a8f38 }
.bg-red { background-color: #f15b22 }
.bg-red-dk { background-color: #bb0000 }
.bg-salmon { background-color: #ee9f7e }
.bg-gray-1 { background-color: #333333 }
.bg-gray-2 { background-color: #595959 }
.bg-gray-3 { background-color: #a6a6a6 }
.bg-gray-30 { background-color: #383838 }
.bg-gray-4 { background-color: #e6e6e6 }
.bg-gray-5 { background-color: #f2f2f2 }
.bg-gray-6 { background-color: #fafafa }

/* APP */
.compact {
    line-height: 1.4;
}

.center.error {
    text-align: center;
}

.padded {
    padding-left: 10px;
    padding-right: 10px;
}

@import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700);
body {
    font-family: 'Open Sans', sans-serif;
}