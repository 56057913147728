/* RESET  [ Styleguide.ArccosGolf.com ] */
html {
    font-size: 14px;
    height: 100%;
    font-family: sans-serif;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
blockquote,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    box-sizing: border-box;
    font-family: inherit;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

a {
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}

/* [ HTML5 Block Elements ] */
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    font-size: 0.9rem;
    min-height: 100%;
}

code,
pre {
    font-family: monospace;
    font-size: 16px
}

dd {
    padding-bottom: 15px;
}

dt {
    font-weight: bold;
    font-size: 115%;
    padding: 10px 0;
}

input,
button,
textarea,
select,
optgroup,
option {
    border: 1px solid #999;
    border-radius: 4px;
    font-style: inherit;
    font-weight: inherit;
    height: 50px;
    line-height: 50px;
    padding: 5px 10px;
}

ol {
    margin-left: 2em;
}


ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

textarea {
    min-height: 150px;
}

th {
    background-color: #333;
    color: #fff;
    padding: 15px;
    text-transform: capitalize;
}

tr:nth-of-type(even) {
    background-color: #ccc;
}