/* Views.css */

.view--centered {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  min-width: 320px;
}

.view--wrapper {
  padding-bottom: 50px;
}

.redirect-container {
  display: flex;
  flex-direction: column;
  background-color: #333;
  height:50vh;
  width:75vw;
  min-width: 320px;
  border-radius: 1.5em;
  color:white;
  justify-content: space-between;
  align-items: center;
}

.redirect-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:100%;
  padding-bottom: 50px;
}

.redirect-header {
  width: 100%;
  max-height: 50px;
  /*background-color: blue;*/
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.redirect-logo {
  height:50px;
}

.title {
  background: #333;
  color: #fff;
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 25px;
  padding: 10px;
  margin: 0;
}

